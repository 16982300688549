import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import ContentSection from '../../ContentSection';
import IntercomButton from '../../Intercom/IntercomButton';
import CheckIcon from '../../../assets/icons/font-awesome/solid/check.svg';

const WhySerpApi = () => {
  const { t } = useTranslation('SerpApi');
  const title = t('WhySerpApi.title');
  const listTitle = t('WhySerpApi.listTitle');
  const listSubtitle = t('WhySerpApi.listSubtitle');

  const seList = [
    t('WhySerpApi.se1'),
    t('WhySerpApi.se2'),
    t('WhySerpApi.se3'),
    t('WhySerpApi.se4'),
    t('WhySerpApi.se5'),
    t('WhySerpApi.se6'),
    t('WhySerpApi.se7'),
  ];

  return (
    <ContentSection title={title}>
      <Row
        className="align-items-center"
        itemScope={true}
        itemType="https://schema.org/ImageObject"
      >
        <Col
          lg="7"
          className="order-lg-last"
          data-aos="zoom-in"
          data-aos-duration="1000"
          data-aos-delay="500"
        >
          <StaticImage
            src="../../../assets/image/serp-api/why-serp-api.svg"
            width={635}
            sizes="(min-width: 635px) 635px, (min-width: 360px) 330px, 100vw"
            breakpoints={[330, 635, 990, 1270]}
            placeholder="blurred"
            alt={title}
            itemProp="contentUrl"
          />
        </Col>

        <Col lg="5">
          <h2 className="title gr-text-4 mb-7 mt-7" itemProp="name">
            {listTitle}
          </h2>

          <p className="gr-text-8">{listSubtitle}</p>

          <div itemProp="description">
            <ul className="card-list list-style-check pl-0 mt-7 mt-lg-11">
              {seList.map((se) => (
                <li className="gr-text-9 ml-5" key={`se-${se}`}>
                  <span className="fa-svg-icon svg-color-primary mr-5">
                    <CheckIcon />
                  </span>
                  {se}
                </li>
              ))}
            </ul>
          </div>

          <IntercomButton text={t('WhySerpApi.ctaText')} isLink />
        </Col>
      </Row>
    </ContentSection>
  );
};

export default WhySerpApi;
