import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import DollarIcon from '../../../assets/icons/font-awesome/solid/dollar-sign.svg';
import CheckIcon from '../../../assets/icons/font-awesome/solid/check.svg';
import IntercomButton from '../../Intercom/IntercomButton';
import ContentSection from '../../ContentSection';
import { roundPrice } from '../../../utils';

const PricingSerpApi = () => {
  const { t } = useTranslation('SerpApi');
  const [isMonthly, setIsMonthly] = useState(true);

  const discountPercent = 20;
  const plan1MonthlyPriceBilledMonthly = 29;
  const plan2MonthlyPriceBilledMonthly = 119;

  const plan1YearlyPrice = plan1MonthlyPriceBilledMonthly * 12;
  const plan2YearlyPrice = plan2MonthlyPriceBilledMonthly * 12;

  const plan1YearlyDiscount = roundPrice(
    (plan1YearlyPrice * discountPercent) / 100
  );
  const plan2YearlyDiscount = roundPrice(
    (plan2YearlyPrice * discountPercent) / 100
  );

  const plan1YearlyPriceBilledYearly = plan1YearlyPrice - plan1YearlyDiscount;
  const plan2YearlyPriceBilledYearly = plan2YearlyPrice - plan2YearlyDiscount;

  const plan1MonthlyPriceBilledYearly = plan1YearlyPriceBilledYearly / 12;
  const plan2MonthlyPriceBilledYearly = plan2YearlyPriceBilledYearly / 12;

  const plan1MonthlyPrice = roundPrice(
    isMonthly ? plan1MonthlyPriceBilledMonthly : plan1MonthlyPriceBilledYearly
  );

  const plan2MonthlyPrice = roundPrice(
    isMonthly ? plan2MonthlyPriceBilledMonthly : plan2MonthlyPriceBilledYearly
  );

  const plan1NumberOfQueries = (50_000).toLocaleString();
  const plan1Features = [
    t('Pricing.plan1.feature1', { numberOfQueries: plan1NumberOfQueries }),
    t('Pricing.plan1.feature2'),
    t('Pricing.plan1.feature3'),
    t('Pricing.plan1.feature4'),
    t('Pricing.plan1.feature5'),
    t('Pricing.plan1.feature6'),
  ];

  const plan2NumberOfQueries = (150_000).toLocaleString();
  const plan2Features = [
    t('Pricing.plan2.feature1', { numberOfQueries: plan2NumberOfQueries }),
    t('Pricing.plan2.feature2'),
    t('Pricing.plan2.feature3'),
    t('Pricing.plan2.feature4'),
    t('Pricing.plan2.feature5'),
    t('Pricing.plan2.feature6'),
  ];

  return (
    <ContentSection id="pricing" title={t('Pricing.title')}>
      <div className="text-center">
        <div
          className="mb-13 d-inline-flex position-relative"
          id="pricing-dynamic-deck--head"
        >
          <span className="period month gr-text-8 gr-text-color ">
            {t('Pricing.periodToggle.label1')}
          </span>

          <div
            className={classNames('btn-toggle mx-3 price-deck-trigger', {
              active: !isMonthly,
            })}
            onClick={(e) => {
              e.preventDefault();
              setIsMonthly(!isMonthly);
            }}
          >
            <span className="round" />
          </div>

          <span className="period year gr-text-8 gr-text-color">
            {t('Pricing.periodToggle.label2')}
          </span>

          <span className="badge gr-badge text-primary gr-text-12 gr-bg-blue-opacity-1 rounded-pill text-uppercase ml-1 ml-md-5">
            {t('Pricing.yearlyDiscountPercent', { discountPercent })}
          </span>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-sm-10 mb-9">
            <div className="pricing-card d-flex flex-column justify-content-between h-100 bg-white gr-hover-shadow-1 border text-left pt-9 pb-9 pr-9 pr-xl-10 pl-9 pl-xl-10 bg-white rounded-10">
              <div>
                <span className="small-title gr-text-12 text-primary font-weight-bold text-uppercase">
                  {t('Pricing.plan1.title')}
                </span>

                <p className="small" style={{ minHeight: 38 }}>
                  {t('Pricing.plan1.description')}
                </p>

                <div className="d-flex align-items-end mt-2">
                  <span className="currency mr-2 gr-text-6 font-weight-bold line-spacing-none text-blackish-blue">
                    <span className="fa-svg-icon">
                      <DollarIcon />
                    </span>
                  </span>

                  <Trans t={t} i18nKey="Pricing.plan1.monthlyPrice">
                    <span className="price-value gr-text-2 font-weight-bold line-spacing-none mb-0 text-blackish-blue">
                      {{ plan1MonthlyPrice }}
                    </span>
                    <span className="mx-2 gr-text-9 text-blackish-blue">/</span>
                    <span className="per gr-text-9 text-blackish-blue">
                      month
                    </span>
                  </Trans>
                </div>

                <div style={{ minHeight: 52 }}>
                  <span className="price-bottom-text gr-text-11 text-blackish-blue gr-opacity-7 d-inline-flex">
                    {!isMonthly && (
                      <div>
                        {t('Pricing.plan1.yearlyPrice', {
                          price: plan1YearlyPriceBilledYearly,
                        })}

                        <div className="text-danger">
                          {t('Pricing.plan1.yearlyDiscount', {
                            discount: plan1YearlyDiscount,
                          })}
                        </div>
                      </div>
                    )}
                  </span>
                </div>

                <ul className="card-list list-style-check pl-0 mt-5">
                  {plan1Features.map((feature) => (
                    <li className="gr-text-9" key={`plan1-${feature}`}>
                      <span className="fa-svg-icon svg-color-success mr-5">
                        <CheckIcon />
                      </span>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="text-center">
                <IntercomButton text={t('Pricing.plan1.ctaText')} isLink />
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-10 mb-9">
            <div className="pricing-card d-flex flex-column justify-content-between h-100 bg-white gr-hover-shadow-1 border border-primary text-left pt-9 pb-9 pr-9 pr-xl-10 pl-9 pl-xl-10 bg-white rounded-10">
              <div>
                <label
                  htmlFor="react-select-package-options-input"
                  className="small-title gr-text-12 text-primary font-weight-bold text-uppercase mb-0"
                >
                  {t('Pricing.plan2.title')}
                </label>

                <p className="small">{t('Pricing.plan2.description')}</p>

                <div className="d-flex align-items-end mt-2">
                  <span className="currency mr-2 gr-text-6 font-weight-bold line-spacing-none text-blackish-blue">
                    <span className="fa-svg-icon">
                      <DollarIcon />
                    </span>
                  </span>

                  <Trans t={t} i18nKey="Pricing.plan2.monthlyPrice">
                    <span className="price-value gr-text-2 font-weight-bold line-spacing-none mb-0 text-blackish-blue">
                      {{ plan2MonthlyPrice }}
                    </span>
                    <span className="mx-2 gr-text-9 text-blackish-blue">/</span>
                    <span className="per gr-text-9 text-blackish-blue">
                      month
                    </span>
                  </Trans>
                </div>

                <div style={{ minHeight: 52 }}>
                  <span className="price-bottom-text gr-text-11 text-blackish-blue gr-opacity-7 d-inline-flex">
                    {!isMonthly && (
                      <div>
                        {t('Pricing.plan2.yearlyPrice', {
                          price: plan2YearlyPriceBilledYearly,
                        })}

                        <div className="text-danger">
                          {t('Pricing.plan2.yearlyDiscount', {
                            discount: plan2YearlyDiscount,
                          })}
                        </div>
                      </div>
                    )}
                  </span>
                </div>

                <ul className="card-list list-style-check pl-0 mt-5">
                  {plan2Features.map((feature) => (
                    <li className="gr-text-9" key={`plan2-${feature}`}>
                      <span className="fa-svg-icon svg-color-success mr-5">
                        <CheckIcon />
                      </span>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>

              <IntercomButton text={t('Pricing.plan2.ctaText')} />
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-10 mb-9">
            <div
              className="pricing-card d-flex flex-column justify-content-between h-100
                   gr-hover-shadow-1 border text-left pt-9 pb-9 pr-9 pr-xl-13  pl-9 pl-xl-13 bg-white rounded-10"
            >
              <div>
                <span className="small-title gr-text-12 text-primary font-weight-bold text-uppercase">
                  {t('Pricing.plan3.title')}
                </span>

                <p className="small">{t('Pricing.plan3.description')}</p>

                <div className="d-flex mt-2">
                  <div className="text-blackish-blue gr-text-7 font-weight-bold mt-5 mb-10">
                    {t('Pricing.plan3.price')}
                  </div>
                </div>

                <div>{t('Pricing.plan3.text')}</div>
              </div>

              <div className="text-center">
                <IntercomButton text={t('Pricing.plan3.ctaText')} isLink />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContentSection>
  );
};
export default PricingSerpApi;
