import React from 'react';
import ContentSection from '../../ContentSection';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import RocketIcon from '../../../assets/icons/font-awesome/solid/rocket.svg';
import RedoIcon from '../../../assets/icons/font-awesome/solid/redo.svg';
import DollarIcon from '../../../assets/icons/font-awesome/solid/dollar-sign.svg';
import IntercomButton from '../../Intercom/IntercomButton';

function Features() {
  const { t } = useTranslation('SerpApi');
  const title = t('Features.title');

  const items = [
    {
      icon: RocketIcon,
      title: t('Features.feature1.title'),
      text: t('Features.feature1.text'),
      text2: t('Features.feature1.text2'),
    },
    {
      icon: RedoIcon,
      title: t('Features.feature2.title'),
      text: t('Features.feature2.text'),
      text2: t('Features.feature2.text2'),
    },
    {
      icon: DollarIcon,
      title: t('Features.feature3.title'),
      text: t('Features.feature3.text'),
      text2: t('Features.feature3.text2'),
    },
  ];

  return (
    <ContentSection id="features" title={title} background={2}>
      <Row>
        {items.map((item, index) => {
          return (
            <Col lg="4" key={`feature-${index}`}>
              <div className="d-flex pb-5">
                <span
                  className="svg-color-primary text-center mr-6"
                  style={{ minWidth: 64 }}
                >
                  <item.icon height="64px" />
                </span>

                <div>
                  <h3 className="gr-text-8 font-weight-bold mb-0">
                    {item.title}
                  </h3>
                  <p className="mt-5">{item.text}</p>
                  <p className="mt-5">{item.text2}</p>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>

      <div className="text-center">
        <IntercomButton text={t('Features.ctaText')} />
      </div>
    </ContentSection>
  );
}

export default Features;
