import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import ContentSection from '../../ContentSection';
import IntercomButton from '../../Intercom/IntercomButton';

const LocalizedResults = () => {
  const { t } = useTranslation('SerpApi');
  const title = t('LocalizedResults.title');
  const description = t('LocalizedResults.description');
  const description2 = t('LocalizedResults.description2');

  return (
    <ContentSection background={2}>
      <Row
        className="align-items-center"
        itemScope={true}
        itemType="https://schema.org/ImageObject"
      >
        <Col
          lg="7"
          data-aos="zoom-in"
          data-aos-duration="1000"
          data-aos-delay="500"
          className="px-lg-20"
        >
          <StaticImage
            src="../../../assets/image/serp-api/localized-results.png"
            width={635}
            sizes="(min-width: 495px) 495px, (min-width: 360px) 330px, 100vw"
            breakpoints={[330, 495, 990]}
            placeholder="blurred"
            alt={title}
            className="shadow-1"
            itemProp="contentUrl"
          />
        </Col>

        <Col lg="5">
          <h2 className="title gr-text-4 mb-7 mt-7" itemProp="name">
            {title}
          </h2>
          <div itemProp="description">
            <p className="gr-text-8">{description}</p>
            <p className="gr-text-8">{description2}</p>
          </div>
          <IntercomButton text={t('LocalizedResults.ctaText')} isLink />
        </Col>
      </Row>
    </ContentSection>
  );
};

export default LocalizedResults;
