import React from 'react';
import MetaTags from '../components/Seo/MetaTags';
import { useTranslation } from 'react-i18next';
import HeroSerpApi from '../components/pages/SerpApi/HeroSerpApi';
import WhySerpApi from '../components/pages/SerpApi/WhySerpApi';
import LocalizedResults from '../components/pages/SerpApi/LocalizedResults';
import AllDevices from '../components/pages/SerpApi/AllDevices';
import Brands from '../components/Brands';
import Features from '../components/pages/SerpApi/Features';
import SerpApiFAQ from '../components/pages/SerpApi/FAQ';
import PricingSerpApi from '../components/pages/SerpApi/PricingSerpApi';

const KeywordTrackerPage = () => {
  const { t } = useTranslation('SerpApi');

  return (
    <>
      <MetaTags title={t('seo.title')} description={t('seo.description')} />
      <HeroSerpApi />
      <WhySerpApi />
      <LocalizedResults />
      <AllDevices />
      <Brands title={t('Brands.title')} noDescription />
      <PricingSerpApi />
      <Features />
      <SerpApiFAQ />
    </>
  );
};

export default KeywordTrackerPage;
