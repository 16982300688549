import React from 'react';
import FAQ from '../../FAQ';
import ContentSection from '../../ContentSection';
import { useTranslation } from 'react-i18next';

function SerpApiFAQ() {
  const { t } = useTranslation('SerpApi');
  const items = [
    {
      question: t('FAQ.item1.question'),
      answer: t('FAQ.item1.answer'),
    },
    {
      question: t('FAQ.item2.question'),
      answer: t('FAQ.item2.answer'),
    },
    {
      question: t('FAQ.item3.question'),
      answer: t('FAQ.item3.answer'),
    },
    {
      question: t('FAQ.item4.question'),
      answer: t('FAQ.item4.answer'),
    },
    {
      question: t('FAQ.item5.question'),
      answer: t('FAQ.item5.answer'),
    },
  ];

  return (
    <ContentSection>
      <FAQ items={items} />
    </ContentSection>
  );
}

export default SerpApiFAQ;
