import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import ContentSection from '../../ContentSection';
import IntercomButton from '../../Intercom/IntercomButton';

const AllDevices = () => {
  const { t } = useTranslation('SerpApi');
  const title = t('AllDevices.title');
  const description = t('AllDevices.description');
  const description2 = t('AllDevices.description2');

  return (
    <ContentSection>
      <Row
        className="align-items-center"
        itemScope={true}
        itemType="https://schema.org/ImageObject"
      >
        <Col
          lg="7"
          className="order-lg-last"
          data-aos="zoom-in"
          data-aos-duration="1000"
          data-aos-delay="500"
        >
          <StaticImage
            src="../../../assets/image/serp-api/all-devices.svg"
            width={635}
            sizes="(min-width: 635px) 635px, (min-width: 360px) 330px, 100vw"
            breakpoints={[330, 635, 990, 1270]}
            placeholder="blurred"
            alt={title}
            itemProp="contentUrl"
          />
        </Col>

        <Col lg="5">
          <h2 className="title gr-text-4 mb-7 mt-7" itemProp="name">
            {title}
          </h2>

          <div itemProp="description">
            <p className="gr-text-8">{description}</p>
            <p className="gr-text-8">{description2}</p>
          </div>

          <IntercomButton text={t('AllDevices.ctaText')} isLink />
        </Col>
      </Row>
    </ContentSection>
  );
};

export default AllDevices;
